<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="wallpaperunidades">
                    <div>
                        Convênios
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="container-fluid px-5">
                    <div class="row row-interno mt-3 text-center">
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/aspemg.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/aurorasaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/bradescosaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/cemigsaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/copasssaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/medgoldsaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/notredameintermedicasaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/qualitysaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/sulamericasaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/unimed.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/usisaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/valesaude.png" class="w-100">
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2">
                            <img src="@/assets/images/yousaude.png" class="w-100">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="container-fluid px-5">
                    <div class="row row-interno mt-3 text-center">
                        <div class="col-12">
                            <div>Aceitamos os principais convênios:</div>
                        </div>
                        <div class="col-12 mt-5">
                            <ul>
                                <ol>Unimed</ol>
                                <ol>Notredame Intermédica</ol>
                                <ol>Sul América Saúde</ol>
                                <ol>Bradesco Saúde</ol>
                                <ol>ASPEMG</ol>
                                <ol>You Saúde</ol>
                                <ol>Cemig Saúde</ol>
                                <ol>Aurora Saúde</ol>
                                <ol>MedGold</ol>
                                <ol>USISAÚDE</ol>
                                <ol>COPASS Saúde</ol>
                                <ol>Vale Saúde</ol>
                                <ol>Cemig</ol>
                                <ol>Quality Saúde</ol>
                                <ol>SEPARE</ol>
                                <ol>Clínica Simples Saúde</ol>
                                <ol>SINDINAPI</ol>
                                <ol>SIND-UTE</ol>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: "ConveniosView",

    }
</script>

<style scoped>
    .container-fluid{

    }

    .wallpaperunidades{
        background: url("@/assets/images/WallpaperUnidades.png");
        background-size: cover;
        height: 300px;
        color: white;
        display: flex;
        align-items: center;
        font-size: 2.5rem;
        padding: 20px;
    }

    *{
        background: #F0F0F0
    }

    div{
        background: transparent;
    }
    
    *{
        margin: 0;
        padding: 0;
    }

    .row{
        background: #F0F0F0;
    }

    .row-interno{
        padding: 10px;
    }

</style>