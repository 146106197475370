<template>
    <NavbarElement />
</template>

<script>
    import NavbarElement from '@/elements/NavbarElement.vue';

    export default{
        name: "HeaderComponent",
        components: {
            NavbarElement
        }
    }
</script>

<style scoped>

</style>