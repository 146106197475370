<template>
    <div class="container-fluid">
        <div class="row">
            
            <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                <card-element titulo="Triglicerides">
                    <template v-slot:corpo>
                        <p>
                            O exame de triglicérides mede a quantidade de triglicerídeos no sangue, uma forma de gordura que serve como fonte de energia para o corpo. Este exame é importante para avaliar o risco de doenças cardiovasculares e outros problemas de saúde. 
                        </p>

                        <p>
                            Jejum não obrigatório.
                        </p>

                        Preparo:<br>
                        
                        - Abstinência de álcool.<br><br>

                        Método: Enzimático automatizado<br><br>

                        Material: Sangue<br><br>

                        Liberação: 1 dia
                        
                    </template>
                </card-element>
            </div>

            <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                <card-element titulo="T4 Tiroxina Livre">
                    <template v-slot:corpo>
                        <p>
                            O exame de T4 livre, ou tiroxina livre, é um exame de sangue que mede a quantidade de um hormônio da tireoide no organismo. Ele é importante para avaliar o funcionamento da glândula tireoide, que é essencial para o crescimento, desenvolvimento e metabolismo do corpo.
                        </p>

                        Jejum não obrigatório.<br><br>

                        Material: Sangue<br><br>

                        Liberação: 1 dia
                    </template>
                </card-element>
            </div>

            <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                <card-element titulo="Parasitológico de Fezes">
                    <template v-slot:corpo>
                        <p> O exame parasitológico de fezes (EPF) é um teste que identifica a presença de parasitas intestinais, como vermes e protozoários, em uma pessoa. 
                            O exame é feito a partir da análise de uma amostra de fezes e é realizado com base em critérios morfológicos, ou seja, a forma dos seres vivos é procurada e estudada. 
                        </p><br>

                        Preparo:<br>

                        - Não se aplica.<br><br>

                        Coleta:<br>

                        - Antes de coletar as fezes, se necessário, urinar no vaso sanitário para evitar a contaminação do material.

                        <br>- Evitar o uso de antiácidos, laxantes e de contraste oral (utilizado em exames radiológicos) no mínimo 3 dias antes da coleta das fezes ou conforme orientação médica.

                        <br>- Defecar em vasilhame limpo e seco.

                        <br>- Retirar frações de fezes em diferentes partes do bolo fecal (início, meio e fim) e colocar em frasco fornecido pelo laboratório ou adquirido em farmácia, sem líquido conservante, de modo que complete pelo menos meio frasco.

                        <br>- Sempre que houver muco (catarro), pus ou sangue, colher esta porção para que seja analisada, informando a presença dessas substâncias ao entregar o material.

                        <br>- Caso seja visualizado algum parasita, coletá-lo informando o achado ao atendente no momento da entrega do material para o adequado processamento da amostra.

                        <br>Material: fezes frescas e sem conservante

                        <br>Recipiente: frasco fornecido pelo laboratório

                        <br>Liberação: 5 dias
                    </template>
                </card-element>

            </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Ferro Sérico">
                        <template v-slot:corpo>
                            <p>
                                O ferro sérico é um exame de sangue que mede a quantidade de ferro livre no plasma sanguíneo. O ferro é um mineral essencial para o funcionamento do corpo humano, pois é fundamental para a produção de hemoglobina, uma proteína que transporta oxigênio para as células.
                            </p>
                        </template>
                    </card-element>
                </div>


                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="1,25 Dihidroxivitamina D">
                        <template v-slot:corpo>
                            <p>
                                O exame 1,25 dihidroxivitamina D, também conhecido como exame de vitamina D, avalia os níveis de calcitriol (1,25 dihidroxivitamina D) no organismo. O calcitriol é a forma ativa da vitamina D, responsável por regular os níveis de cálcio e fósforo no corpo.
                            </p><br>

                            Jejum desejável de 4 horas.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 4 dias
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="TSH (Hormônio Tireoestimulante)">
                        <template v-slot:corpo>
                            <p>
                                O exame TSH, ou tireoestimulante, é um exame de sangue que mede a quantidade de hormônio tireoestimulante no corpo e serve para avaliar a função da glândula tireoide. 
O exame de TSH pode ajudar a diagnosticar alterações na tireoide, como hipotireoidismo ou hipertireoidismo, antes mesmo de o paciente sentir sintomas. Também pode ser usado para acompanhar a evolução de tratamentos em andamento. 

                            </p><br>

                            Jejum desejável de 4 horas.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                
                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Sódio Sérico">
                        <template v-slot:corpo>
                            <p>
                                O exame de sódio sérico, ou exame de sódio no sangue, é um teste laboratorial que verifica a quantidade de sódio presente no organismo. Ele é útil para diagnosticar e controlar condições relacionadas ao equilíbrio de fluidos, eletrólitos e acidez do corpo. 
O exame pode ser solicitado pelo médico para identificar concentrações anormais de sódio, como a hiponatremia (baixo sódio) e a hipernatremia (sódio elevado). 
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Hemograma Automatizado">
                        <template v-slot:corpo>
                            <p>
                                O hemograma automatizado é um exame de sangue que serve para avaliar e quantificar as células sanguíneas, permitindo a detecção de doenças e a monitoração de tratamentos.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Creatinina Sérica">
                        <template v-slot:corpo>
                            <p>
                                A creatinina sérica é um exame que mede a quantidade de creatinina no sangue, uma substância que é produzida nos músculos e eliminada pelos rins. Este exame é um dos mais usados para avaliar a função renal, permitindo a detecção de condições como insuficiência renal e infecção renal em estágios iniciais.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Ureia Sérica">
                        <template v-slot:corpo>
                            <p>
                                O exame de ureia sérica é um exame de sangue que analisa a quantidade de ureia no organismo e é usado para avaliar a função renal. A ureia é um resíduo químico produzido pelo fígado a partir da quebra de proteínas e excretado pelos rins. 
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="TGO(Asparatato Aminotransferase)">
                        <template v-slot:corpo>
                            <p>
                                O exame de TGO, ou transaminase glutâmico-oxalacética, mede os níveis de uma enzima hepática no sangue. É um exame simples, feito por meio de uma coleta de sangue em uma veia do braço, e que auxilia no diagnóstico e monitoramento de diversas condições médicas.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>
            
                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Sexagem Fetal">
                        <template v-slot:corpo>
                            <p>
                                O exame de sexagem fetal é um teste de sangue que permite identificar o sexo do bebê a partir da 8ª semana de gestação. O procedimento é feito a partir de uma amostra de sangue da mãe, que é analisada para identificar fragmentos do DNA fetal.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 7 dias
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Glicose Basal">
                        <template v-slot:corpo>
                            <p>
                                O exame de glicose basal, também conhecido como glicemia em jejum, mede a quantidade de açúcar no sangue de uma pessoa em jejum. É um exame importante para diagnosticar diabetes e monitorar a doença. 
                            </p><br>

                            Jejum obrigatório mínimo de 8 horas e máximo de 14 horas.<br><br>

                            - Evitar bebidas alcoólicas por 24 horas.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Colesterol Total">
                        <template v-slot:corpo>
                            <p>
                                O exame de colesterol total, também conhecido como perfil lipídico, é um exame de rotina que mede os níveis de colesterol e triglicéridos no sangue. O exame é feito a partir de uma amostra de sangue que é retirada do paciente e levada para um laboratório para ser analisada. O técnico avalia a quantidade de colesterol LDL, HDL e VLDL na amostra e soma os valores para chegar ao valor do colesterol total.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            - Abstinência de álcool.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Urina Rotina Automatizada">
">
                        <template v-slot:corpo>
                            <p>
                                O exame de urina automatizado é um procedimento que proporciona agilidade e precisão nos resultados. É importante para avaliar a função renal e afecções do trato urinário, podendo ajudar no diagnóstico e avaliação da eficácia do tratamento.
                            </p><br><br>

                            Preparo:<br>

                            <br>- Jato médio da primeira urina da manhã ou com no mínimo 4 horas de retenção urinária.

                            <br><br>Coleta:

                            <br>- Lavar as mãos com água e sabão.

                            <br>- Lavar o órgão genital com água e sabão.

                            <br>- Mulheres: lavar e secar sempre da frente para trás. Mulheres não devem estar menstruadas, aguardar três dias após o término da menstruação. Abrir os grandes lábios na hora de urinar.

                            <br>- Homens: lavar retraindo o prepúcio, para uma adequada limpeza.

                            <br>- Ambos, desprezar o primeiro jato.

                            <br>- Crianças que necessitem de coletor: após higienização adequada, colocar o coletor que deverá ser trocado a cada 30 minutos com uma nova higienização até que a criança urine. Após a coleta, transferir a urina imediata e assepticamente para frasco esterilizado.

                            <br>- Pacientes cateterizados: desinfetar com álcool a região do cateter próxima a sua união com o tubo de drenagem. Prender com pinça hemostática logo abaixo. Puncionar com agulha calibre 21 e seringa estéril, aspirando a urina. Não desconectar o cateter do tubo de drenagem. Não coletar a urina diretamente da bolsa coletora.

                            <br><br>Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="TGP (Alanina Aminotransferase)">
                        <template v-slot:corpo>
                            <p>
                                O exame TGP (transaminase glutâmico-pirúvica) ou ALT (alanina aminotransferase) é um exame de sangue que mede os níveis da enzima TGP no sangue e ajuda a identificar problemas no fígado.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Potássio Sérico">
                        <template v-slot:corpo>
                            <p>
                                O exame de potássio sérico é um exame laboratorial que mede a concentração de potássio no sangue, ajudando a avaliar o equilíbrio eletrolítico do corpo. O potássio é um eletrólito essencial para o funcionamento do corpo, e alterações nos seus níveis podem prejudicar o funcionamento dos músculos, do sistema nervoso e do coração.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Gama GT">
                        <template v-slot:corpo>
                            <p>
                                O exame Gama GT, ou gama glutamil transferase, é um exame de sangue que mede a quantidade da enzima Gama GT no organismo. É um exame que ajuda a avaliar a função do fígado e a detectar problemas no pâncreas e nas vias biliares.
                            </p><br>

                            Jejum não obrigatório.<br><br>

                            - Não ingerir bebidas alcoólicas 72h antes da coleta.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>

                <div class="col-12 col-md-6 col-lg-6 coluna mt-2">
                    <card-element titulo="Ácido Úrico Sérico">
                        <template v-slot:corpo>
                            <p>
                                O exame de ácido úrico sérico é um exame laboratorial que mede a quantidade de ácido úrico no sangue. É um exame simples e de baixo custo que pode ser útil para:<br> 
                                - Diagnosticar gota, uma doença que causa inflamação nas articulações<br>
                                - Investigar a causa de pedras nos rins<br>
                                - Monitorar o nível de ácido úrico em pessoas em tratamento de quimioterapia ou radioterapia<br>
                                - Investigar condições específicas em gestantes, como a toxemia gravídica<br>
                            </p>

                            Jejum não obrigatório.<br><br>

                            Material: Sangue<br><br>

                            Liberação: 1 dia
                            
                        </template>
                    </card-element>
                </div>


        </div>
    </div>  
</template>
  
  <script>
        import CardElement from '@/elements/CardElement.vue';


      export default{
          name: "ExamesExamesView.vue",
          components: {
            CardElement
          }
      }
  </script>
  
  <style scoped>
        .container-fluid, .row, .coluna{
            background: #F0F0F0;
        }
        p{
            background: transparent;
        }

  </style>